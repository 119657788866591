<template>
  <div>
    <!-- NAVBAR START -->
    <nav id="nav-menu" class="navbar navbar-expand-lg py-lg-3 mb-2 sticky-top">
      <div class="container">
        <!-- logo -->
        <router-link
          v-if="config"
          :to="'/' + userRole.toLocaleLowerCase()"
          class="navbar-brand mr-lg-5 font-size-22 font-weight-bold text-dark"
        >
          <img :src="config.logo" alt="" class="logo-dark mr-2" height="24" />
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="uil uil-bars"></i>
        </button>

        <div class="collapse navbar-collapse">
          <ul
            class="navbar-nav ml-auto align-items-center font-size-16 font-weight-bold"
          >
            <li class="nav-item mr-0 ml-4">
              <router-link
                v-if="user"
                class="btn btn-primary d-none d-lg-inline-flex"
                :to="'/' + userRole.toLocaleLowerCase()"
              >
                <i data-feather="shopping-bag" class="icon-xs mr-2 mt-1"></i>
                Dashboard
              </router-link>

              <router-link
                v-else
                class="btn btn-primary d-none d-lg-inline-flex"
                :to="{ name: 'login' }"
              >
                <i data-feather="shopping-bag" class="icon-xs mr-2 mt-1"></i>
                Login
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    userRole() {
      return this.user ? this.user.role : ''
    },

    config() {
      return this.$store.getters['app/config']
    },
  },

  mounted() {
    if (!this.user) {
      this.$router.push({ name: 'login' })
    }
  },
}
</script>
